import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {
    ProposalSummaryFilter,
    ProposalLaunchFilter, InventoryManagementFilter,
} from '@models/filter';
import {ExistingOrderRow, ExistingProposalRow} from '@models/proposal-launch/proposal-launch';
import {
    ApiProposalData,
    BulkCustomDaypartReturn,
    BulkCustomDaypartUpdate,
    CustomDaypartUpdate,
    EdisonRefreshStatus,
    EdisonPollData,
    OPT_STATUS_RUNNING,
    OptStatusHistory,
    ProposalComment,
    ProposalData,
    ProposalLineComment,
    RfpSummary,
    ScenarioComparisonGrid,
    ScenarioInputs,
    ScenarioOutputSummary,
    SummaryDemoActuals,
    MergedProposalLineDetails
} from '@models/proposal-optimize/proposal-optimize';
import { MatchingProposals} from '../../../proposal-opt/shared/scenario-merge/scenario-merge.component';
import {Grid, LookupItem, GroupedGrid, DateRange, SurveyLookupItem} from '@models/lookup';
import {ProposalSummary} from '@models/proposal-summary/proposal-summary';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RateGuideRow, SimplifiedRateGuideFilter} from '@models/rate-guide/rate-guide';
import {generateUncachedGet, generateCachedGet, parseAnalysisTables, parseScenarioProposalTable,
    SCREEN_NAME_PROPOSAL_SUMMARY, SCREEN_NAME_LAUNCH_PAD_SEARCH, SCREEN_NAME_PROPOSAL_BUILDER,
    SCREEN_NAME_PROPOSAL_INPUT, SCREEN_NAME_LAUNCH_PAD_BUILD, SCREEN_NAME_RATE_GUIDE,
    SCREEN_NAME_INVENTORY_REPORT, SCREEN_NAME_INVENTORY_MANAGEMENT,
    SCREEN_NAME_LAUNCH_PAD_PICK, SCREEN_NAME_TEMPORARY_MERGE,
    SCREEN_NAME_POST_BUY_ANALYSIS} from '@shared/helpers/helpers';
import {AuthService} from '@services/auth/auth.service';
import {UrlStore} from '@shared/helpers/url-store';
import {SimplifiedInventoryAvailabilityFilter} from '@models/inventory-availability/inventory-availability';
import {ProposalBulkLine} from '@models/proposal-bulk-line/proposal-bulk-line';
import {polling} from '@shared/helpers/polling';
import {SalesForceAdvAgencyUpdate} from '@models/status';
import { filter } from 'lodash';
import { SimplifiedPostAnalysisFilter } from '@models/post-analysis/post-analysis';
import { PollRefreshResponse } from 'src/app/proposal-opt/radio/post-buy-analysis/post-buy-analysis.component';

const RANDOM_DELAY = 0;
const millisecondsInDay = 1000 * 60 * 60 * 24;
const POLLING_INTERVAL = 5000;

@Injectable({
    providedIn: 'root'
})
export class ProposalOptService {

    constructor(private http: HttpClient, private authSvc: AuthService) {
    }

    getProposalSummary(filters: ProposalSummaryFilter): Observable<Grid<ProposalSummary>> {
        filters.screenName = SCREEN_NAME_PROPOSAL_SUMMARY;
        return generateUncachedGet<Grid<ProposalSummary>>(UrlStore.api.proposalPro.proposalList, this.http, filters);
    }

    getExistingProposals(filters: ProposalLaunchFilter): Observable<GroupedGrid<ExistingProposalRow>> {
        filters.budget = Number(filters.budget);
        filters.screenName = SCREEN_NAME_LAUNCH_PAD_SEARCH;
        return generateUncachedGet<GroupedGrid<ExistingProposalRow>>(
            UrlStore.api.proposalPro.existingProposalList, this.http, filters
        ).pipe(
            switchMap((grid: GroupedGrid<ExistingProposalRow>) => {
                grid.data.forEach(row => {
                    row.flightRange = Math.round(
                        (
                            new Date(row.flightEnd).getTime()
                            - new Date(row.flightStart).getTime()
                        )
                        / millisecondsInDay);
                });
                return of(grid);
            })
        );
    }

    // This is somewhat indefinitely on hold
    getExistingOrders(filters: ProposalLaunchFilter): Observable<ExistingOrderRow[]> {
        return of([ // TODO: Convert to grid
            {
                orderId: 12345,
                advertiserName: 'Test Advertiser',
                agencyName: 'Test Agency',
                campaignName: 'Test Campaign Name',
                flightStart: new Date(),
                flightEnd: new Date(),
                budget: 23456
            }
        ])
            .pipe(delay(Math.random() * RANDOM_DELAY));
    }

    getRfpSummary(proposalId: number, screenName?: string): Observable<RfpSummary> {
        return generateUncachedGet<RfpSummary>(UrlStore.api.proposalPro.proposalDetails, this.http, {proposalId, screenName});
    }

    getScenarioInputs(proposalId: number, scenarioId?: number, screenName?: string): Observable<ScenarioInputs> {
        return scenarioId ?
            generateUncachedGet<ScenarioInputs>(UrlStore.api.proposalPro.scenarioInputs, this.http, {
                proposalId,
                scenarioId,
                screenName
            }) :
            generateUncachedGet<ScenarioInputs>(UrlStore.api.proposalPro.scenarioInputs, this.http, {
                proposalId,
                screenName
            });
    }

    canUserAccessProposal(proposalId: number): Observable<any> {
        return generateUncachedGet<any>(UrlStore.api.proposalPro.canUserViewProposal,
            this.http, { proposalId });
    }

    getScenarioComparison(proposalId: number): Observable<ScenarioComparisonGrid> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return generateUncachedGet<ScenarioComparisonGrid>(UrlStore.api.proposalPro.scenarioComparison,
            this.http, {proposalId, screenName});
    }

    getScenarioAnalysis(proposalId: number, scenarioId: number): Observable<Grid<any>[]> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return generateUncachedGet<Grid<any>[]>(UrlStore.api.proposalPro.analysis, this.http, {proposalId, scenarioId, screenName})
            .pipe(
                switchMap(params => {
                    // TODO: Decide if this should be moved to the api
                    return of(parseAnalysisTables(params));
                })
            );
    }

    getScenarioMergeAnalysis(proposalId: number[]): Observable<any[]> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return generateUncachedGet<any[]>(UrlStore.api.proposalPro.mergeAnalysis, this.http, {proposalId, screenName});
    }

    getScenarioOutputSummary(proposalId: number, scenarioId?: number): Observable<ScenarioOutputSummary> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        const promise = scenarioId ?
            generateUncachedGet<ScenarioOutputSummary[]>(UrlStore.api.proposalPro.scenarioSummary, this.http, {
                proposalId,
                scenarioId,
                screenName
            }) :
            generateUncachedGet<ScenarioOutputSummary[]>(UrlStore.api.proposalPro.scenarioSummary, this.http, {proposalId, screenName});
        return promise.pipe(
            switchMap((summary: ScenarioOutputSummary[]) => {
                return of(summary[0]);
            })
        );
    }

    getScenarioMergeLineDetails(proposalId: number[]): Observable<MergedProposalLineDetails> {
        const screenName: string = SCREEN_NAME_TEMPORARY_MERGE;
        return generateUncachedGet<MergedProposalLineDetails>(UrlStore.api.proposalPro.mergeProposals, this.http, {proposalId, screenName});
    }

    getScenarioProposal(proposalId: number, scenarioId: number): Observable<ProposalData> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return generateUncachedGet<ApiProposalData>(UrlStore.api.proposalPro.scenarioProposal, this.http,
            {proposalId, scenarioId, screenName})
            .pipe(
                switchMap(params => {
                    // TODO: Decide if this should be moved to the api
                    const config = this.authSvc.getConfig();
                    return of({
                        gridData: parseScenarioProposalTable(params.gridData,
                            config.customerConfig.radio_proposal_opt.proposal_opt.view_planning
                            && config.roleConfig.radio_proposal_opt.proposal_opt.view_planning),
                        snapshotHistory: params.snapshotHistory
                    });
                })
            );
    }

    getProposalComments(proposalId?: number): Observable<Grid<ProposalComment>> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        const url = UrlStore.api.proposalPro.proposalComments;
        return generateUncachedGet<Grid<ProposalComment>>(url, this.http, {proposalId, screenName});
    }

    deleteProposalComment(commentType: string, commentId: number): Observable<{}> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.request<{}>('delete', UrlStore.api.proposalPro.proposalComments, {body: {commentType, commentId, screenName}});
    }

    submitProposalComment(proposalId?: number, comment?: string): Observable<Grid<ProposalComment>> {
        const url = UrlStore.api.proposalPro.proposalComments;
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.post<Grid<ProposalComment>>(url, {proposalId, comment, screenName});
    }

    getProposalLineComments(proposalLineId: number): Observable<Grid<ProposalLineComment>> {
        const url = UrlStore.api.proposalPro.lineComments + '/' + proposalLineId.toString();
        return this.http.get<Grid<ProposalLineComment>>(url);
    }

    saveProposalLineComment(lineParams: any, comment: string): Observable<{
        proposalLineId: number,
        comments: Grid<ProposalLineComment>
    }> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        const url = UrlStore.api.proposalPro.lineComments + '/' + lineParams.data.proposalLineId.toString();
        return this.http.post<{
            proposalLineId: number,
            comments: Grid<ProposalLineComment>,
            screenName
        }>(url, {comment, proposalLine: lineParams.data, scenarioId: lineParams.scenarioId});
    }

    getCloseProposalReasons(screenName?: string): Observable<LookupItem[]> {
        return generateCachedGet(
            true,
            UrlStore.api.lookups.closeReasons,
            this.http,
            {screenName},
            {}
        );
    }

    saveProposal(proposal: any): Observable<RfpSummary> {
        proposal.screenName = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.put<RfpSummary>(UrlStore.api.proposalPro.scenario, proposal);
    }

    runOptimization(proposal: any): Observable<string> {
        proposal.screenName = SCREEN_NAME_PROPOSAL_INPUT;
        return this.http.post<any>(UrlStore.api.proposalPro.optimize, proposal);
    }

    waitOptimization(
        scenarioId: number, everyWait: () => void = () => {}): Observable<OptStatusHistory> {
        const screenName: string = SCREEN_NAME_PROPOSAL_INPUT;
        return generateUncachedGet<any>(
            UrlStore.api.proposalPro.optimize, this.http, {scenarioId, screenName}).pipe(
            polling((optStatus: OptStatusHistory): boolean => {
                everyWait();
                return optStatus.stepFunctionStatus !== OPT_STATUS_RUNNING;
            }, POLLING_INTERVAL));
    }

    getOptimizationHistory(scenarioId: number): Observable<string> {
        const screenName: string = SCREEN_NAME_PROPOSAL_INPUT;
        return generateUncachedGet<any>(
            UrlStore.api.proposalPro.optimizeHistory, this.http, {scenarioId, screenName});
    }

    createProposal(filters: ProposalLaunchFilter): Observable<{ proposalId: number, sfdc_error: boolean }> {
        filters.screenName = SCREEN_NAME_LAUNCH_PAD_BUILD;
        return this.http.post<{ proposalId: number, sfdc_error: boolean }>(UrlStore.api.proposalPro.proposal, filters);
    }

    copyInputsFromProposal(proposalId: number, flightDateRange: DateRange): Observable<{ proposalId: number }> {
        return this.http.post<{ proposalId: number }>(UrlStore.api.proposalPro.copyProposalInputs, {proposalId, flightDateRange});
    }

    copyProposal(proposalId: number, proposalLaunchFilter: ProposalLaunchFilter): Observable<{ proposalId: number, sfdc_error: boolean }> {
        proposalLaunchFilter.screenName = SCREEN_NAME_LAUNCH_PAD_BUILD;
        return this.http.post<{ proposalId: number, sfdc_error: boolean }>(UrlStore.api.proposalPro.copyProposal,
            {proposalId, proposalLaunchFilter});
    }

    /* Should return the RfpSummary with the new scenario inserted
        New Scenario should be last
    */
    createScenario(proposalId: number, scenarioName: string, makePrimary?: boolean): Observable<RfpSummary> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.scenario, {proposalId, scenarioName, makePrimary,
            screenName});
    }

    /* Should return the RfpSummary with the new scenario inserted
        New Scenario should last
    */
    copyScenario(scenarioId: number, scenarioName: string, makePrimary?: boolean): Observable<RfpSummary> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.copyScenario, {scenarioId, scenarioName, makePrimary, screenName});
    }

    /* Should return the RfpSummary with the deleted scenario removed
    */
    deleteScenario(proposalId: number, scenarioId: number): Observable<RfpSummary> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.request<RfpSummary>('delete', UrlStore.api.proposalPro.scenario, {body: {proposalId, scenarioId, screenName}});
    }

    makePrimaryScenario(proposalId: number, scenarioId: number): Observable<RfpSummary> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.makePrimary, {proposalId, scenarioId, screenName});
    }

    getRateGuide(rateGuideFilterIds: SimplifiedRateGuideFilter): Observable<GroupedGrid<RateGuideRow>> {
        rateGuideFilterIds.screenName = SCREEN_NAME_RATE_GUIDE;
        return this.http.post<any>(UrlStore.api.rateGuide.rateGuide, rateGuideFilterIds);
    }

    getInventoryAvailability(filters: SimplifiedInventoryAvailabilityFilter): Observable<any> {
        filters.screenName = SCREEN_NAME_INVENTORY_REPORT;
        return this.http.post<any>(UrlStore.api.inventoryAvailability.availability, filters);
    }

    submitProposalForApproval(comment: string, proposalId: number, scenarioId: number,
                              emailAttachment: string, hasDuplicateRows: boolean): Observable<RfpSummary> {
        const formData = new FormData();
        formData.append('comment', comment);
        formData.append('proposalId', proposalId.toString());
        formData.append('scenarioId', scenarioId.toString());
        formData.append('hasDuplicateRows', hasDuplicateRows.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        if (emailAttachment !== '') {
            // Don't bother with empty attachments
            formData.append('emailAttachment', emailAttachment);
        }
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.submitProposal, formData);
    }

    submitProposalForApprovalMerge(comment: string, proposalId: number[],
                                   emailAttachment: string,
                                   scenarioNames: string): Observable<boolean> {
        const formData = new FormData();
        formData.append('comment', comment);
        formData.append('proposalId', proposalId.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        if (emailAttachment !== '') {
            // Don't bother with empty attachments
            formData.append('emailAttachment', emailAttachment);
            formData.append('scenarioNames', scenarioNames);
        }
        return this.http.post<boolean>(UrlStore.api.proposalPro.submitProposalForMerge, formData);
    }

    unsubmitProposal(comment: string, proposalId: number, scenarioId: number): Observable<RfpSummary> {
        const formData = new FormData();
        formData.append('comment', comment);
        formData.append('proposalId', proposalId.toString());
        formData.append('scenarioId', scenarioId.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.unsubmitProposal, formData);
    }

    changedSincePending(scenarioId: number): Observable<boolean> {
        const formData = new FormData();
        formData.append('scenarioId', scenarioId.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        return this.http.post<boolean>(UrlStore.api.proposalPro.changedSincePending, formData);
    }

    publishProposal(scenarioId: number,
                    emailAttachment: string,
                    revisionScenarioId: number = null,
                    splitByChannel = false,
                    splitByWeek = false,
                    splitBy2Week = false): Observable<RfpSummary> {
        const formData = new FormData();
        formData.append('scenarioId', scenarioId.toString());
        if (emailAttachment !== '') {
            // Don't bother with empty attachments
            formData.append('emailAttachment', emailAttachment);
        }
        formData.append('revisionScenarioId', revisionScenarioId ? revisionScenarioId.toString() : '');
        formData.append('splitByChannel', splitByChannel.toString());
        formData.append('splitByWeek', splitByWeek.toString());
        formData.append('splitBy2Week', splitBy2Week.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.publishProposal, formData);
    }

    approveProposal(reason: string, proposalId: number, scenarioId: number, emailAttachment: string): Observable<RfpSummary> {
        const formData = new FormData();
        formData.append('reason', reason);
        formData.append('proposalId', proposalId.toString());
        formData.append('scenarioId', scenarioId.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        if (emailAttachment !== '') {
            // Don't bother with empty attachments
            formData.append('emailAttachment', emailAttachment);
        }
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.approveProposal, formData);
    }

    rejectProposal(reason: string, proposalId: number, scenarioId: number, emailAttachment: string): Observable<RfpSummary> {
        const formData = new FormData();
        formData.append('reason', reason);
        formData.append('proposalId', proposalId.toString());
        formData.append('scenarioId', scenarioId.toString());
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        if (emailAttachment !== '') {
            // Don't bother with empty attachments
            formData.append('emailAttachment', emailAttachment);
        }
        return this.http.post<RfpSummary>(UrlStore.api.proposalPro.rejectProposal, formData);
    }

    getClientProposalFooter(): Observable<string> {
        // tslint:disable: max-line-length
        return of(`
        ******All Spots Air EST/EDT******
            •       NOTE: This information is the property of Sirius XM Radio Inc. and is strictly confidential.  Use is strictly limited to Sirius XM Radio Inc. employees, advertisers and their agencies and potential advertisers and their agencies.

        Summary of Investment and Payment Terms:
            •       Standard Payment Terms: Sirius XM Radio Inc. adheres to n/30 payment policy. All invoices must be paid within 30 days of receipt.
            •       Sirius XM Radio Inc. guarantees a minimum of 10 minutes of advertiser separation.
            •       All spots aired within 15 minutes of ordered dayparts are considered run as ordered.
            •       All spots ordered on Howard Stern Channels 100 and 101, The Faction Channel and Live programming will be considered to have run as ordered  as long as they air within the content of the programs.
            •       All Live Reads are considered to be aired as ordered as long as the Talent reads the call to action and/or offer correctly.
            •       Sirius XM is not obligated to air no charge spots and will air no charge spots based on inventory availability.
            •       Make Good: Although spots will not be preempted for rate considerations,  there are times when  spots are missed. Missed spots will be made good within flight. Any spots not made good will not be billed.
            •       Impressions provided on this proposal are estimated by Edison and not guaranteed.
            •       Sirius XM reserves all rights to air advertiser commercials on all signal delivery streams including satellite channels and mirrored web channels.
            •       SiriusXM Radio Inc. adheres to 15 day cancellation policy on all brand spots and a 60 day cancellation policy for all sponsorship and live talent reads.
            •       This cancellation policy does not supersede otherwise agreed to cancellation stipulations.
            •       Written  notice must  be sent with a copy to: Sirius XM Radio Inc. Business Office 125 Park Avenue, New York, NY 10017 or Siriusxmbusinessoffice@siriusxm.com.
            •       Approval of this order supersedes all other orders and/or correspondence.
            •       All Sirius XM Satellite orders will bill monthly, using the standard broadcast month.

        Commercial Creative:
            •       If client provides self-contained fully produced commercials, we will need their spot along with PAD data and traffic instructions a minimum of 6 business days prior to the campaign start.
            •       If Sirius XM Radio Inc. is producing the commercial, we will need copy points/script 10 days prior to campaign start (Traffic and PAD can be sent 6 days prior).
            •       Commercial and PAD Creative can be changed if at least 6 business days lead time is given.

        Additional Terms:
                All advertisements are subject to review and approval by Sirius XM Radio Inc (“SiriusXM”).  SiriusXM reserves the right to edit, reject or cancel any advertisement, space or time reservation or position commitment at any time and for any reason. In providing content to SiriusXM for broadcast, Advertiser irrevocably grants
                SiriusXM a non-exclusive, royalty-free license to use, distribute, and sublicense such content on its platforms for purposes of broadcasting the ads. Advertiser represents and warrants that the content provided to SiriusXM: (i) does not violate any third party rights; (ii) complies with all federal and state laws,
                including, but not limited to false advertising and unfair competition and any gambling, lottery laws, sweepstakes and contest laws; and (iii) is not defamatory, pornographic or obscene. Advertiser further represents that the product or service being promoted through any campaign hereunder (a) is not subject to any
                ongoing investigation by any regulatory or law enforcement authority; (b) the terms of any offer presented in the ad shall be clearly and conspicuously disclosed to the consumer in compliance with all laws; (c) it has proper documented substantiation for any claims, testimonials, endorsements, and other promotional
                materials used to sell its products or services and that all claims and statements are factually accurate, non-deceptive, non-misleading and represent the honest opinions, finding, beliefs and/or experience of the endorser or what a new consumer will experience; and (d) it will fulfill any commitments or
                representations made in the ads themselves.  Advertiser agrees to indemnify, defend and hold harmless SiriusXM., its parents, subsidiaries and affiliates and their current and former officers, directors, employees, and agents from and against any and all demands, claims, losses, fees, expenses, liability, damages
                and attorneys’ fees and costs (including interest and penalties) that may be asserted arising out of or resulting from (i) any breach of the representations and warranties or any other material term of this IO by the Advertiser, its agencies, or its affiliates; (ii) any claim arising or resulting from the sale or
                license of Advertiser’s goods or services as promoted in the ads; (iii) any government or regulatory action, including, but not limited to, investigations, litigation, or other legal proceedings, related to such advertisements or from the sale or license of Advertiser’s goods or services as promoted in the
                advertisements, including all attorneys’ fees incurred by SiriusXM as a result of complying with such action; or (iv) any other act, omission or misrepresentation by Advertiser directly or indirectly related to this IO. SiriusXM shall have the option to participate in such defense through counsel of its own
                choosing. In the event this indemnity includes claims against the employees, agents, or subsidiaries of SiriusXM those employees, agents, or subsidiaries shall be indemnified just as their principal would be. SiriusXM reserves the right to reject or remove any ad or URL link embodied within an ad at any time
                in the event SiriusXM determines in its sole reasonable discretion that such ad or linked content does not meet SiriusXM standards or comply with this IO, or that such ad or linked content is unlawful or inappropriate. SiriusXM also reserves the right to demand third party verification for any claims made in
                any ad and to terminate this IO in the event that such verification is not promptly provided or is unsatisfactory, in SiriusXM sole discretion. Notwithstanding the foregoing provisions, SiriusXM has no obligation to monitor ads for compliance with applicable laws or regulations and shall have not liability
                for any violation of same.

        Non-Discrimination Policy:  Sirius XM Radio Inc. does not discriminate in the sale of advertising.  Advertiser or any entity purchasing on behalf of Advertiser represents and warrants that it is not purchasing advertising from Sirius XM with the intention of discriminating on the basis of race or ethnicity
        `);
        // tslint:enable: max-line-length

    }

    publishRateGuide(rateGuideData: RateGuideRow[]): Observable<RateGuideRow[]> {
        rateGuideData[0].screenName = SCREEN_NAME_RATE_GUIDE;
        return this.http.post<any>(UrlStore.api.rateGuide.save, rateGuideData);
    }

    getInventoryManagement(filters: InventoryManagementFilter): Observable<Grid<any>> {
        filters.screenName = SCREEN_NAME_INVENTORY_MANAGEMENT;
        return this.http.post<any>(UrlStore.api.admin.inventoryManagement, filters);
    }

    getPostBuyAnalysis(filters: SimplifiedPostAnalysisFilter): Observable<Grid<any>> {
        filters.screenName = SCREEN_NAME_POST_BUY_ANALYSIS;
        return this.http.post<any>(UrlStore.api.postBuyAnalysis.postanalysis, filters);
    }

    getRefreshRatings(filters: SimplifiedPostAnalysisFilter): Observable<string> {
        filters.screenName = SCREEN_NAME_POST_BUY_ANALYSIS;
        return this.http.post<any>(UrlStore.api.postBuyAnalysis.ratings, filters);
    }

    pollForEdisonResults(scheduleId: string): Observable<PollRefreshResponse> {
        const screenName = SCREEN_NAME_POST_BUY_ANALYSIS;
        let url = UrlStore.api.postBuyAnalysis.ratings;
        url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify({scheduleId, screenName})).toString()));
        return this.http.get<any>(url);
    }

    saveInventoryManagement(inventoryManagementData: any[]): Observable<any[]> {
        const screenName: string = SCREEN_NAME_INVENTORY_MANAGEMENT;
        return this.http.put<any[]>(UrlStore.api.admin.inventoryManagement, {inventoryManagementData, screenName});
    }

    copyProposalLine(proposalLineId: number): Observable<{ proposalLineId: number }> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.put<{ proposalLineId }>(UrlStore.api.proposalPro.proposalLines, {proposalLineId, screenName});
    }

    deleteProposalLine(proposalLineId: number): Observable<{}> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.request<{}>('delete', UrlStore.api.proposalPro.proposalLines, {body: {proposalLineId, screenName}});
    }

    saveProposalLine(
        scenarioId: number,
        channel: LookupItem,
        daypart: LookupItem,
        spotType: LookupItem,
        spotLength: LookupItem):
        Observable<{
            proposalLineId: number,
            rate: {
                original: number,
                current: number
            }
        }> {
            const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
            return this.http.post<any>(UrlStore.api.proposalPro.proposalLines,
                {scenarioId, channel, daypart, spotType, spotLength, screenName});
    }

    updateProposalLine(proposalLine: any, scenarioId: number): Observable<any> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.put<{ any }>(UrlStore.api.proposalPro.updateLine, {proposalLine, scenarioId, screenName});
    }

    updateProposal(outputs, scenarioId, agencyCommission, resetRateOverrides= false): Observable<ProposalData> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.post<any>(UrlStore.api.proposalPro.scenarioProposal,
            {outputs, scenarioId, agencyCommission, screenName, resetRateOverrides});
    }

    updateProposalMergeView(outputs, agencyCommission, resetRateOverrides= false): Observable<boolean> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.post<any>(UrlStore.api.proposalPro.mergeProposals,
            {outputs, agencyCommission, screenName, resetRateOverrides});
    }

    refreshNielsenRatings(scenarioId: number, primaryDemo: LookupItem, secondaryDemo: LookupItem, tertiaryDemo: LookupItem,
                          surveys: LookupItem[]): Observable<SummaryDemoActuals[]> {
                            const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
                            return this.http.put<SummaryDemoActuals[]>(UrlStore.api.proposalPro.nielsenOrEdisonRatings,
            {scenarioId, primaryDemo, secondaryDemo, tertiaryDemo, surveys, screenName});
    }

    getRefreshStatus(scenarioId: number): Observable<EdisonRefreshStatus> {
                const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
                let url = UrlStore.api.proposalPro.edisonRatingsStatus;
                url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify({scenarioId, screenName})).toString()));
                return this.http.get<EdisonRefreshStatus>(url);
    }

    getRefreshToggleValue(): Observable<any> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        let url = UrlStore.api.proposalPro.refreshToggleValue;
        url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify({screenName})).toString()));
        return this.http.get<any>(url);
    }

    refreshEdisonRatings(scenarioId: number, primaryDemo: LookupItem, secondaryDemo: LookupItem, tertiaryDemo: LookupItem,
                         surveys: SurveyLookupItem[]): Observable<SummaryDemoActuals[]> {
                            const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
                            return this.http.put<SummaryDemoActuals[]>(UrlStore.api.proposalPro.nielsenOrEdisonRatings,
         {scenarioId, primaryDemo, secondaryDemo, tertiaryDemo, surveys, screenName, api : 'Edison'});
    }

    pollForRefreshStatus(scenarioId: number): Observable<EdisonPollData> {
           const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
           let url = UrlStore.api.proposalPro.edisonRatingsStatusPoll;
           url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify({scenarioId, screenName})).toString()));
           return this.http.get<EdisonPollData>(url);
    }

    getWideOrbitXML(scenarioId: number, splitByChannel = false, splitByWeek = false, splitBy2Week = false,
                    folderGUID = '', reTry = false): Observable<any> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        const url = UrlStore.api.woms.xml + '/' + scenarioId.toString();
        return generateUncachedGet(url, this.http, {splitByChannel, splitByWeek, splitBy2Week,
            folderGUID, reTry, screenName}, {responseType: 'text'});
    }

    getAgencyXML(scenarioId: number, officeName: string, buyerName: string): Observable<any> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        const url = UrlStore.api.ams.xml + '/' + scenarioId.toString();
        return generateUncachedGet(url, this.http, {officeName, buyerName, screenName}, {responseType: 'text'});
    }

    getScenarioSnapshot(scenarioId: number, snapshotId: number): Observable<ApiProposalData> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        let url = UrlStore.api.proposalPro.proposalDifferences;
        url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify({scenarioId, snapshotId, screenName})).toString()));
        return this.http.get<ApiProposalData>(url);
    }

    updateCustomDaypart(daypartId: number, channelId: number, spotTypeId: number, scenarioId: number): Observable<CustomDaypartUpdate> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.put<CustomDaypartUpdate>(UrlStore.api.proposalPro.customDaypartUpdate,
            {daypartId, channelId, spotTypeId, scenarioId, screenName});
    }

    createCustomDaypart(daypart: LookupItem, channelId: number, spotTypeId: number, scenarioId: number): Observable<CustomDaypartUpdate> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.put<CustomDaypartUpdate>(UrlStore.api.proposalPro.createCustomDaypart,
            {daypart, channelId, spotTypeId, scenarioId, screenName});
    }

    createBulkCustomDaypart(
        daypart: LookupItem, channelIds: number [], spotTypeIds: number[], scenarioId: number
    ): Observable<BulkCustomDaypartUpdate> {
        const screenName: string = SCREEN_NAME_PROPOSAL_BUILDER;
        return this.http.put<BulkCustomDaypartReturn>(UrlStore.api.proposalPro.createBulkCustomDaypart,
            {daypart, channelIds, spotTypeIds, scenarioId, screenName}).pipe(
            switchMap((newBulkCustomDaypart: BulkCustomDaypartReturn) => {
                return of({
                    id: newBulkCustomDaypart.id,
                    name: newBulkCustomDaypart.name,
                    originalIds: new Set(newBulkCustomDaypart.originalIds),
                    customDaypartIds: newBulkCustomDaypart.customDaypartIds
                });
            }));
    }

    bulkAddLines(scenarioId: number, rowData: ProposalBulkLine[]): Observable<any> {
        const formData = new FormData();
        const jsonRows = JSON.stringify(rowData);
        formData.append('scenarioId', scenarioId.toString());
        formData.append('rowData', jsonRows);
        formData.append('screenName', SCREEN_NAME_PROPOSAL_BUILDER);
        return this.http.post<any>(UrlStore.api.proposalPro.bulkAddLines, formData);
    }

    createUpdateAdvertiser(sfdcAccId: string, sfdcAccName: string, woAdvId: number):
    Observable<{response: SalesForceAdvAgencyUpdate}> {
        const screenName: string = SCREEN_NAME_LAUNCH_PAD_PICK;
        return this.http.put<{response: SalesForceAdvAgencyUpdate}>
        (UrlStore.api.proposalPro.updateAdvertisers,
            {sfdc_acc_id: sfdcAccId, sfdc_acc_name: sfdcAccName, wo_adv_id: woAdvId,
                screenName});
    }

    createUpdateAgency(sfdcAgencyId: string, sfdcAgencyName: string, woAgencyId: number):
     Observable<{response: SalesForceAdvAgencyUpdate}> {
        const screenName: string = SCREEN_NAME_LAUNCH_PAD_PICK;
        return this.http.put<{response: SalesForceAdvAgencyUpdate}>
        (UrlStore.api.proposalPro.updateAgencies,
            {sfdc_agency_id: sfdcAgencyId, sfdc_agency_name: sfdcAgencyName, wo_agency_id: woAgencyId,
                screenName});
    }

    getMatchingProposals(advertiserId: number, agencyId: number): Observable<MatchingProposals[]> {
        const screenName: string = SCREEN_NAME_TEMPORARY_MERGE;
        return generateUncachedGet<MatchingProposals[]>(UrlStore.api.proposalPro.matchingProposals,
                                                        this.http, {advertiserId, agencyId, screenName});
        }

    getAutoApproval(scenarioId: number): Observable<boolean> {
        let url = UrlStore.api.proposalPro.autoApproval;
        url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify({scenarioId})).toString()));
        return this.http.get<boolean>(url);
    }
}
